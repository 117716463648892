<template>
   <div class="flex-shrink-0 z-20 hidden md:block w-64 h-full text-white fixed bg-gray-800 border-r border-gray-900" id="side-navigation">
   <div class="pt-8 pb-2 px-6">
      <router-link to="#!">
         <div class="flex items-center">
            <div class="shrink-0">
               <img :src="admin.account.avatar_url || require('@/assets/images/user.png')" class="rounded-full w-10" alt="Avatar">
            </div>
            <div class="grow ml-3">
               <p class="text-sm font-semibold text-white">{{ admin.account.first_name + ' ' + admin.account.last_name | capitalize }}</p>
               <p class="text-gray-300 text-xs">{{ admin.email }}</p>
            </div>
         </div>
      </router-link>
   </div>
   <ul class="relative px-2 my-4">
      <li class="relative my-2">
         <router-link :to="{ name: 'AdminDashboard' }" 
            :class="$route.name == 'AdminDashboard' ? 'bg-indigo-700' : ''" class="py-2 hover:bg-indigo-700 rounded flex items-center text-sm px-6 overflow-hidden text-gray-200 text-ellipsis 
            whitespace-nowrap transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">
         <svg xmlns="http://www.w3.org/2000/svg" data-name="Layer 1" viewBox="0 0 24 24" class="w-4 h-4 mr-3">
               <path fill="currentColor" d="M10,13H4a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V14A1,1,0,0,0,10,13ZM9,19H5V15H9ZM20,3H14a1,1,0,0,0-1,1v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,20,3ZM19,9H15V5h4Zm1,7H18V14a1,1,0,0,0-2,0v2H14a1,1,0,0,0,0,2h2v2a1,1,0,0,0,2,0V18h2a1,1,0,0,0,0-2ZM10,3H4A1,1,0,0,0,3,4v6a1,1,0,0,0,1,1h6a1,1,0,0,0,1-1V4A1,1,0,0,0,10,3ZM9,9H5V5H9Z"/>
            </svg>
         <span>Dashboard</span>
         </router-link>
      </li>
      <li class="relative my-2" id="sidenavSecEx1">
         <span class="py-2 hover:bg-indigo-700 rounded flex items-center text-sm px-6 overflow-hidden text-gray-200 text-ellipsis 
            whitespace-nowrap transition duration-300 ease-in-out cursor-pointer"  :class="$route.name == 'EscrowTransactions' || $route.name == 'PaymentTransactions' ? 'bg-indigo-700' : ''" data-mdb-ripple="true" data-mdb-ripple-color="primary" data-bs-toggle="collapse" data-bs-target="#collapseSidenavSecEx1" aria-expanded="false" aria-controls="collapseSidenavSecEx1">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-3" viewBox="0 0 20 20" fill="currentColor">
               <path d="M4 4a2 2 0 00-2 2v1h16V6a2 2 0 00-2-2H4z" />
               <path fill-rule="evenodd" d="M18 9H2v5a2 2 0 002 2h12a2 2 0 002-2V9zM4 13a1 1 0 011-1h1a1 1 0 110 2H5a1 1 0 01-1-1zm5-1a1 1 0 100 2h1a1 1 0 100-2H9z" clip-rule="evenodd" />
            </svg>
            <span>Transactions</span>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-3 h-3 ml-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
               <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
            </svg>
            </span>
            <ul class="relative accordion-collapse collapse" id="collapseSidenavSecEx1" aria-labelledby="sidenavSecEx1" data-bs-parent="#side-navigation">
            <li class="relative my-1">
               <router-link :to="{ name: 'EscrowTransactions'}" class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Escrow Transactions</router-link>
            </li>
            <li class="relative my-1">
               <router-link :to="{ name: 'PaymentTransactions' }" class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Payment Transactions</router-link>
            </li>
         </ul>
      </li>
      <li class="relative my-2" id="sidenavSecEx2">
         <span class="py-2 hover:bg-indigo-700 rounded flex items-center text-sm px-6 overflow-hidden text-gray-200 text-ellipsis 
            whitespace-nowrap transition duration-300 ease-in-out cursor-pointer" :class="$route.name == 'UserAccounts' || $route.name == 'StaffAccounts' || $route.name == 'AdminAccounts' ? 'bg-indigo-700' : ''" data-mdb-ripple="true" data-mdb-ripple-color="primary" data-bs-toggle="collapse" data-bs-target="#collapseSidenavSecEx2" aria-expanded="false" aria-controls="collapseSidenavSecEx2">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 7a4 4 0 11-8 0 4 4 0 018 0zM12 14a7 7 0 00-7 7h14a7 7 0 00-7-7z" />
            </svg>
            <span>Accounts</span>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-3 h-3 ml-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
               <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
            </svg>
         </span>
         <ul class="relative accordion-collapse collapse" id="collapseSidenavSecEx2" aria-labelledby="sidenavSecEx2" data-bs-parent="#side-navigation">
            <li class="relative my-1">
               <router-link :to="{ name: 'UserAccounts'}" class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">User Acounts</router-link>
            </li>
            <li class="relative my-1">
               <router-link :to="{ name: 'StaffAccounts'}" class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Staff Accounts</router-link>
            </li>
            <li class="relative my-1">
               <router-link :to="{ name: 'AdminAccounts'}" class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Admin Accounts</router-link>
            </li>
         </ul>
      </li>
      <li class="relative my-2" id="sidenavSecEx3">
         <a class="py-2 hover:bg-indigo-700 rounded flex items-center text-sm px-6 overflow-hidden text-gray-200 text-ellipsis 
            whitespace-nowrap transition duration-300 ease-in-out cursor-pointer" data-mdb-ripple="true" data-mdb-ripple-color="primary" data-bs-toggle="collapse" data-bs-target="#collapseSidenavSecEx3" aria-expanded="false" aria-controls="collapseSidenavSecEx3">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 h-4 mr-3">
               <path fill="currentColor" d="M19,7H18V6a3,3,0,0,0-3-3H5A3,3,0,0,0,2,6H2V18a3,3,0,0,0,3,3H19a3,3,0,0,0,3-3V10A3,3,0,0,0,19,7ZM5,5H15a1,1,0,0,1,1,1V7H5A1,1,0,0,1,5,5ZM20,15H19a1,1,0,0,1,0-2h1Zm0-4H19a3,3,0,0,0,0,6h1v1a1,1,0,0,1-1,1H5a1,1,0,0,1-1-1V8.83A3,3,0,0,0,5,9H19a1,1,0,0,1,1,1Z"/></svg>
            <span>Payment Settings</span>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-3 h-3 ml-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
               <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
            </svg>
         </a>
         <ul class="relative accordion-collapse collapse" id="collapseSidenavSecEx3" aria-labelledby="sidenavSecEx3" data-bs-parent="#side-navigation">
            <li class="relative my-1">
               <a class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Payment Methods</a>
            </li>
            <li class="relative my-1">
               <a class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Manual Gateway</a>
            </li>
            <li class="relative my-1">
               <a class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Deposit Requests</a>
            </li>
            <li class="relative my-1">
               <a class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Payment Log</a>
            </li>
         </ul>
      </li>
      <li class="relative my-2" id="sidenavSecEx4">
         <span class="py-2 hover:bg-indigo-700 rounded flex items-center text-sm px-6 overflow-hidden text-gray-200 text-ellipsis 
            whitespace-nowrap transition duration-300 ease-in-out cursor-pointer" data-mdb-ripple="true" data-mdb-ripple-color="primary" data-bs-toggle="collapse" data-bs-target="#collapseSidenavSecEx4" aria-expanded="false" aria-controls="collapseSidenavSecEx4">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 h-4 mr-3">
               <path fill="currentColor" d="M6,11a1,1,0,1,0,1,1A1,1,0,0,0,6,11Zm12,0a1,1,0,1,0,1,1A1,1,0,0,0,18,11Zm2-6H4A3,3,0,0,0,1,8v8a3,3,0,0,0,3,3H20a3,3,0,0,0,3-3V8A3,3,0,0,0,20,5Zm1,11a1,1,0,0,1-1,1H4a1,1,0,0,1-1-1V8A1,1,0,0,1,4,7H20a1,1,0,0,1,1,1ZM12,9a3,3,0,1,0,3,3A3,3,0,0,0,12,9Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,12,13Z"/>
            </svg>
            <span>Payout Settings</span>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-3 h-3 ml-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
               <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
            </svg>
         </span>
         <ul class="relative accordion-collapse collapse" id="collapseSidenavSecEx4" aria-labelledby="sidenavSecEx4" data-bs-parent="#side-navigation">
            <li class="relative my-1">
               <a class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Payout Methods</a>
            </li>
            <li class="relative my-1">
               <a class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Withdrawal Requests</a>
            </li>
            <li class="relative my-1">
               <a class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Withdrawal Log</a>
            </li>
         </ul>
      </li>
   </ul>
  <hr class="my-2 border-t border-gray-900">
   <ul class="relative px-2 my-4">
      <li class="relative my-2" id="sidenavSecEx5">
         <span class="py-2 hover:bg-indigo-700 rounded flex items-center text-sm px-6 overflow-hidden text-gray-200 text-ellipsis 
            whitespace-nowrap transition duration-300 ease-in-out cursor-pointer" :class="$route.name == 'SupportTicket' ? 'bg-indigo-700' : ''" data-mdb-ripple="true" data-mdb-ripple-color="primary" data-bs-toggle="collapse" data-bs-target="#collapseSidenavSecEx5" aria-expanded="false" aria-controls="collapseSidenavSecEx5">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 5v2m0 4v2m0 4v2M5 5a2 2 0 00-2 2v3a2 2 0 110 4v3a2 2 0 002 2h14a2 2 0 002-2v-3a2 2 0 110-4V7a2 2 0 00-2-2H5z" />
            </svg>
            <span>Support Tickets</span>
            <svg aria-hidden="true" focusable="false" data-prefix="fas" class="w-3 h-3 ml-auto" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
               <path fill="currentColor" d="M207.029 381.476L12.686 187.132c-9.373-9.373-9.373-24.569 0-33.941l22.667-22.667c9.357-9.357 24.522-9.375 33.901-.04L224 284.505l154.745-154.021c9.379-9.335 24.544-9.317 33.901.04l22.667 22.667c9.373 9.373 9.373 24.569 0 33.941L240.971 381.476c-9.373 9.372-24.569 9.372-33.942 0z"></path>
            </svg>
         </span>
         <ul class="relative accordion-collapse collapse" id="collapseSidenavSecEx5" aria-labelledby="sidenavSecEx5" data-bs-parent="#side-navigation">
            <li class="relative my-1">
               <router-link :to="{ name: 'SupportTicket' } " class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">All Tickets</router-link>
            </li>
            <li class="relative my-1">
               <a class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Open Tickets</a>
            </li>
            <li class="relative my-1">
               <a class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Closed Tickets</a>
            </li>
            <li class="relative my-1">
               <a class="flex items-center text-xs py-4 pl-12 pr-6 h-6 overflow-hidden text-gray-200 text-ellipsis whitespace-nowrap rounded hover:text-blue-600 hover:bg-blue-50 transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">Answered Tickets</a>
            </li>
         </ul>
      </li>
      <li class="relative my-2">
         <router-link :to="{ name: 'Subscribers' }" 
            :class="$route.name == 'Subscribers' ? 'bg-indigo-700' : ''" class="py-2 hover:bg-indigo-700 rounded flex items-center text-sm px-6 overflow-hidden text-gray-200 text-ellipsis 
            whitespace-nowrap transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-3" viewBox="0 0 20 20" fill="currentColor">
               <path d="M8 9a3 3 0 100-6 3 3 0 000 6zM8 11a6 6 0 016 6H2a6 6 0 016-6zM16 7a1 1 0 10-2 0v1h-1a1 1 0 100 2h1v1a1 1 0 102 0v-1h1a1 1 0 100-2h-1V7z" />
            </svg>
         <span>Subscribers</span>
         </router-link>
      </li>
      <li class="relative my-2">
         <router-link :to="{ name: 'BasicSettings' }" 
            :class="$route.name == 'BasicSettings' ? 'bg-indigo-700' : ''" class="py-2 hover:bg-indigo-700 rounded flex items-center text-sm px-6 overflow-hidden text-gray-200 text-ellipsis 
            whitespace-nowrap transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M10.325 4.317c.426-1.756 2.924-1.756 3.35 0a1.724 1.724 0 002.573 1.066c1.543-.94 3.31.826 2.37 2.37a1.724 1.724 0 001.065 2.572c1.756.426 1.756 2.924 0 3.35a1.724 1.724 0 00-1.066 2.573c.94 1.543-.826 3.31-2.37 2.37a1.724 1.724 0 00-2.572 1.065c-.426 1.756-2.924 1.756-3.35 0a1.724 1.724 0 00-2.573-1.066c-1.543.94-3.31-.826-2.37-2.37a1.724 1.724 0 00-1.065-2.572c-1.756-.426-1.756-2.924 0-3.35a1.724 1.724 0 001.066-2.573c-.94-1.543.826-3.31 2.37-2.37.996.608 2.296.07 2.572-1.065z" />
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
            </svg>
            <span>Basic Settings</span>
         </router-link>
      </li>
      <li class="relative my-2">
         <router-link :to="{ name: 'EmailSettings' }" 
            :class="$route.name == 'EmailSettings' ? 'bg-indigo-700' : ''" class="py-2 hover:bg-indigo-700 rounded flex items-center text-sm px-6 overflow-hidden text-gray-200 text-ellipsis 
            whitespace-nowrap transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" />
            </svg>
            <span>Email Settings</span>
         </router-link>
      </li>
      <li class="relative my-2">
         <router-link :to="{ name: 'SmsSettings' }" 
            :class="$route.name == 'SmsSettings' ? 'bg-indigo-700' : ''" class="py-2 hover:bg-indigo-700 rounded flex items-center text-sm px-6 overflow-hidden text-gray-200 text-ellipsis 
            whitespace-nowrap transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 18h.01M8 21h8a2 2 0 002-2V5a2 2 0 00-2-2H8a2 2 0 00-2 2v14a2 2 0 002 2z" />
            </svg>
            <span>SMS Settings</span>
         </router-link>
      </li>
      <li class="relative my-2">
         <router-link :to="{ name: 'PushNotifications' }" 
            :class="$route.name == 'PushNotifications' ? 'bg-indigo-700' : ''" class="py-2 hover:bg-indigo-700 rounded flex items-center text-sm px-6 overflow-hidden text-gray-200 text-ellipsis 
            whitespace-nowrap transition duration-300 ease-in-out" data-mdb-ripple="true" data-mdb-ripple-color="primary">
            <svg xmlns="http://www.w3.org/2000/svg" class="w-4 h-4 mr-3" fill="none" viewBox="0 0 24 24" stroke="currentColor">
               <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9" />
            </svg>
            <span>Push Notifications</span>
         </router-link>
      </li>
      <li class="relative my-2">
         <div class="py-2 hover:bg-indigo-700 rounded flex cursor-pointer items-center text-sm px-6 overflow-hidden text-gray-200 text-ellipsis bg-gray-700
            whitespace-nowrap transition duration-300 ease-in-out" @click="logout()" data-mdb-ripple="true" data-mdb-ripple-color="primary">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="w-4 h-4 mr-3">
               <path fill="currentColor" d="M12.59,13l-2.3,2.29a1,1,0,0,0,0,1.42,1,1,0,0,0,1.42,0l4-4a1,1,0,0,0,.21-.33,1,1,0,0,0,0-.76,1,1,0,0,0-.21-.33l-4-4a1,1,0,1,0-1.42,1.42L12.59,11H3a1,1,0,0,0,0,2ZM12,2A10,10,0,0,0,3,7.55a1,1,0,0,0,1.8.9A8,8,0,1,1,12,20a7.93,7.93,0,0,1-7.16-4.45,1,1,0,0,0-1.8.9A10,10,0,1,0,12,2Z"/>
            </svg>
            <span>Logout</span>
         </div>
      </li>
  </ul>
  <!--div class="text-center bottom-0 absolute w-full">
   <hr class="m-0 border-t border-gray-900">
    <p class="py-2 text-sm text-gray-400">Escrowlock.com v.1.0.1</p>
  </div-->
</div>
</template>


<script>
import { mapGetters } from 'vuex'

export default {
   name: "AdminSidebar",

    data() {
        return {
           
        }
    },

    methods: {
        async logout() {
            try {
               let res = await this.$store.dispatch('auth/admin_token_terminate')
               this.$toast.success(res.message)
            } catch (error) {
               this.$toast.error(error.response.message)
               if(error.response && error.response.status === 401) {
                  this.$store.commit('logout');
                  this.router.push({ name: 'AdminLogin'});
               }
            }
        }
   },

   computed: {
      ...mapGetters("auth", ["admin"]),
   }
}
</script>