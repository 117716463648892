<template>
   <nav class="bg-white border-b border-gray-200">
      <div class="container px-10 py-4 mx-auto md:flex md:justify-between md:items-center">
         <div class="flex items-center justify-between">
            <div to="/" class="text-indigo-900">
               <div class="flex items-center justify-center flex-shrink-0 h-auto text-base xl:text-base text-heading font-semibold focus:outline-none">
                  <span class="text-xl pl-2 text-heading font-semibold focus:outline-none">Super Admin Panel</span>
               </div>
            </div>
            <!-- Mobile menu button -->
               <div class="flex md:hidden">
                  <button type="button" class="text-gray-500 hover:text-gray-600 focus:outline-none focus:text-gray-600" aria-label="toggle menu">
                     <svg viewBox="0 0 24 24" class="w-6 h-6 fill-current">
                        <path fill-rule="evenodd" d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"></path>
                     </svg>
                  </button>
               </div>
            </div>

         <!-- Mobile Menu open: "block", Menu closed: "hidden" -->
         <div class="items-center">
            <div class="flex justify-center space-x-5 text-sm">
               <router-link to="/" class="relative text-gray-700 transition-colors mt-1 duration-200 transform hover:text-indigo-900">
                  <span class="font-semibold">Visit Site</span>
               </router-link>
            </div>
         </div>
      </div>
   </nav>
</template>


<script>
import { mapGetters } from 'vuex'
export default {
   name: "AdminNav",
   components: {
      
   },
   data() {
      return {
         
      }
   },

   methods: {
      async logout() {

      }
   },

   computed: {
      ...mapGetters("adminAuth", ["admin"]),
   }
}
</script>