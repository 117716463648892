<template>
   <div>
      <template v-if="isLoading">
         <div class="text-center grid grid-cols-1 gap-4 place-content-center bg-white">
         </div>
      </template>
      <template v-else>
         <div class="bg-gray-100 flex antialiased">
            <aside class="">
               <AdminSidebar />
            </aside>
            <main class="flex-1 flex flex-col min-h-screen h-full overflow-x-hidden overflow-y-auto ml-64">
               <AdminNav />
               <main class="flex-grow">
                  <router-view />
               </main>
               <footer class="inset-x-0 bottom-0 w-full bg-white border-t border-gray-200 p-5 text-gray-500 font-medium 2xl:p-5">
                  <div class="mx-4">
                     <div class="flex flex-col-reverse md:flex-row text-center md:justify-center mx-auto max-w-full px-4 md:px-8 2xl:px-16">
                        <p class="text-xs lg:text-sm leading-6">
                        Copyright &copy; {{ new Date().getFullYear() }} <a class="font-bold text-indigo-900 transition-colors duration-200 ease-in-out hover:text-body" href="https://escrowlock.com">Escrowlock.</a> All rights reserved
                        </p>
                     </div>
                  </div>
               </footer>
            </main>
         </div>
      </template>
   </div>
</template>


<script>
import AdminNav from '@/components/layouts/admin/navbar.vue'
import AdminSidebar from '@/components/layouts/admin/sidebar.vue'
import { mapActions } from 'vuex'

export default {
   name: "AdminLayout",

   components: {
      AdminNav,
      AdminSidebar
   },

   data() {
      return {
         isLoading: false
      }
   },

   methods: {
      ...mapActions('admin', ['check_route_status']),
      
      async checkIfRouteActive() {
         this.isLoading = false
         const endpoint_params = {
            id: this.$route.params.id,
            token: this.$route.params.token
         }
         
         try {
            let res = await this.check_route_status(endpoint_params)
            res.data
            this.isLoading = false
         } catch(error) {
            this.$router.push('/')
         }
      }
   },

   mounted() {
      this.checkIfRouteActive()
   }
}
</script>